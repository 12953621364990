export const contactForm__data = {
  imageProperties: {
    image:
      "https://images.unsplash.com/flagged/photo-1566838616793-29a4102a5b0e?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    mainImageBrightness: 0.95,
  },

  formTitle: "Contact us",
  formDescription:
    "If you need to send us pictures, you can attach them at the end of this form",

  inputStyles: {
    inputBorderColor: "red", // Doesnt work yet
  },

  inputs: [
    {
      title: "Full name",
      placeholder: "Full name",
      type: "text",
      name: "user_name", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "Phone",
      placeholder: "Phone",
      type: "text",
      name: "user_phoneNumber", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "Email",
      placeholder: "Email",
      type: "email",
      name: "user_email", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "Address",
      placeholder: "Address",
      type: "text",
      name: "user_address", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "City",
      placeholder: "City",
      type: "text",
      name: "city", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "State",
      placeholder: "State",
      type: "text",
      name: "state", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "Postal code",
      placeholder: "Postal code",
      type: "text",
      name: "postal_code", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
  ],

  inputs_sec2: [
    {
      title: "Tell us about your project",
      placeholder: "About your project",
      type: "text",
      name: "about_project", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "What is the approximate size of your project?",
      placeholder: "Approximate size of your project",
      type: "text",
      name: "approximate_size", // Needed for Emailjs
      maxLength: null,
      required: false,
    },
    {
      title:
        "Has your existing concrete been previously sealed, painted or covered in carpet/tile? ",
      placeholder: "Type here...",
      type: "text",
      name: "concrete_been_previously", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title:
        "Does your existing concrete have any cracks or chips that need to be repaired?",
      placeholder: "Type here...",
      type: "text",
      name: "concrete_needs_to_be_repaired", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
    {
      title: "When is your ideal time-frame for completing this project?",
      placeholder: "Ideal time frame",
      type: "text",
      name: "ideal_time_frame", // Needed for Emailjs
      maxLength: null,
      required: true,
    },
  ],
};
