export const serviceCards__data = {
  cardType: "playingCard",
  imageOnTopOrBottom: "bottom",

  cardType__playingCard: {
    titleFontSize: 24,
  },

  cardType__textOnImageSquared: {
    size: 220,
    titleFontSize: 24,
  },

  cardType__giammariaCard: {
    titleFontSize: 24,
  },

  cards: [
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/654aa969a7c5f40f544119bd.jpeg",
      title: "EPOXY",
      category: "",
      description:
        "Create and install custom-designed floor inlays, medallions, and decorative borders made from various flooring materials, adding artistic and unique elements to the floor.",

      backgroundColor: "#4166E4", // Make sure its a HEX
    },

    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/654ab49e92b8576b9e96a228.jpeg",
      title: "DRYWALL",
      category: "",
      description:
        "Drywall is the solid foundation on which we build environments that reflect your vision and style. At EPOXY DRYWALL & PAINT, we understand the importance of a solid foundation, and drywall is essential in creating a perfect canvas for your home or business.",

      backgroundColor: "#4166E4", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/654ab91adabf984f0866bd50.jpeg",
      title: "PAINT",
      category: "",
      description:
        "At EPOXY DRYWALL & PAINT, we believe in the power of paint to transform spaces into authentic works of art. Our painting services go beyond simply applying colors to walls; It's about giving life to your ideas, injecting personality and warmth into every corner.",

      backgroundColor: "#4166E4", // Make sure its a HEX
    },
  ],
};

export const giammariaCard__data = {
  cardType: "textOnImageSquared",
  imageOnTopOrBottom: "bottom",

  cardType__playingCard: {
    titleFontSize: 24,
  },

  cardType__textOnImageSquared: {
    size: 220,
    titleFontSize: 24,
  },

  cardType__giammariaCard: {
    titleFontSize: 24,
  },

  cards: [
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db130410d205c26ddc47.jpeg",
      title: "CREEKBED",
      category: "",
      description: "",

      backgroundColor: "#FF1FFF", // Make sure its a HEX
    },

    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db13f63b4d226ae99570.jpeg",
      title: "DOMINO",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },

    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db13f2603d54bdc59234.jpeg",
      title: "GRAVEL",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db13bd0c379aa22ebd7f.jpeg",
      title: "NIGHTFALL",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db13bd0c377d662ebd7d.jpeg",
      title: "ORBIT",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553dbe3f63b4d5f4ee99690.jpeg",
      title: "OUTBACK",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553dc2ebd0c37c2902ebf4e.jpeg",
      title: "SHORELINE",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db13bd0c3743e42ebd7e.jpeg",
      title: "TIDAL WAVE",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_320/u_https://storage.googleapis.com/msgsndr/904NEVFZATeEUX4WRLPy/media/6553db132fddbaa060299d5f.jpeg",
      title: "WOMBAT",
      category: "",
      description: "",

      backgroundColor: "#FFFFFF", // Make sure its a HEX
    },
  ],
};
