// Images
import { websiteLogo } from "../../constants/constants_images";

// Constant Info
import {
  websiteName,
  contactEmail,
  businessAddress,
} from "../../constants/constants_info";

// Social Medias
import {
  facebook_link,
  instagram_link,
  tiktok_link,
} from "../../constants/constants_socialMedias";

export const footer__data = {
  websiteName: websiteName,
  contactEmail: contactEmail,
  businessAddress: businessAddress,

  mainColors: {
    backgroundColor: "white",
    textColor: "black",

    socialMediaIconsColor: "#161718",
  },

  logoImage: {
    image: websiteLogo,
    height: 100,
  },

  socialMedias: [
    {
      icon: <i class="fa-brands fa-facebook-f"></i>,
      iconImage: null,
      iconCustomColor: "#4267B2",
      socialMediaLink: facebook_link,
    },

    {
      icon: <i class="fa-brands fa-instagram"></i>,
      iconImage: null,
      iconCustomColor: "#4c68d7",
      socialMediaLink: instagram_link,
    },
    {
      icon: <i class="fa-brands fa-tiktok"></i>,
      iconImage: null,
      iconCustomColor: "#6441a5",
      socialMediaLink: tiktok_link,
    },
  ],
};
