import React from "react";

// Data
import {
  giammariaCard__data,
  serviceCards__data,
} from "../myFolder/editComponents/cards/cardsData";
import {
  ITC_customEpoxyFloors,
  ITC_drywall,
  ITC_painting,
} from "../myFolder/editComponents/other/data_ImageAndTextContainer";

import ImageAndTextContainer from "../preMadeComponents/otherComponents/ImageAndTextContainer/ImageAndTextContainer";
import CardsContainer from "../preMadeComponents/containers/CardsContainer/CardsContainer";
import AllCards from "../preMadeComponents/cards/AllCards";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";

function ServicesPage() {
  return (
    <div>
      <ImageAndTextContainer data={ITC_customEpoxyFloors} />

      <CardsContainer
        scroll={true}
        marginTop={40}
        gapBetween={10}
        title={"Textures"}
        titlePosition={"Center"}
      >
        {giammariaCard__data.cards.map((card, index) => {
          return <AllCards card={card} card__data={giammariaCard__data} />;
        })}
      </CardsContainer>

      <ImageAndTextContainer data={ITC_drywall} />
      <ImageAndTextContainer data={ITC_painting} />
      <Footer />
    </div>
  );
}

export default ServicesPage;
