export const reviews__data = {
  cardType: "default",

  reviews: [
    {
      username: "jizzel Love",
      userPhoto: null,
      stars: 5,
      location: "",
      reviewText:
        "He does great work and so so fast . I was very impressed with the quality and speed , they moved all of our stuff out and took such good care of our stuff , nothing was damaged !!! He is great",
    },
    {
      username: "Ashley LeBaron",
      userPhoto: null,
      stars: 5,
      location: "",
      reviewText:
        "I absolutely love my new epoxy floors in my garage. It was a game changer for my little soap business that I run out of it. I highly recommend this company because they’re honest and trustworthy and they provide a great service at a great price and they’re very professional.",
    },
    {
      username: "Jessica",
      userPhoto: null,
      stars: 5,
      location: "Bozeman Montana",
      reviewText:
        "Lehi and his team were on time, professional, and kept my property clean during and after the work. I'd give more stars if I could!!",
    },
  ],
};
